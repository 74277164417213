import axios from 'axios';
import { getAppInsights } from './TelemetryService'; // Adjust the import according to your project structure
// import { getToken } from './tokenUtility'; // Adjust the import according to your project 


let isInteractionInProgress = false;
let interactionPromise = null;
let authenticateRequests = true;
let baseUrl = "";

const createAxiosInstance = (msalInstance, request,baseUri,authenticate) => {

    baseUrl = baseUri;
    authenticateRequests = authenticate;
    const axiosInstance = axios.create({
         baseURL: baseUri,
        withCredentials: true, // Ensures cookies are sent with every request
    });

    axiosInstance.interceptors.request.use(
        async (config) => {
            // Inject Bearer Token
            if(authenticateRequests){
                const token = await getToken(msalInstance, request,baseUri);
                config.headers.Authorization = `Bearer ${token}`;
            }
           
            
            // Log the request
            const appInsights = getAppInsights();
            if (appInsights) {
                appInsights.trackEvent({
                    name: 'HTTP Request',
                    properties: {
                        method: config.method,
                        url: config.url,
                        headers: config.headers,
                    },
                });
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            // Handle error logging here if needed
            if (error.config && error.config.method === 'post' && !error.response) {
                // Log the failed POST request
                const recordFailedPostUrl = `${baseUrl}/Shared/Menu/RecordFailedPostRequest?requestName=${encodeURIComponent(error.config.url)}&message=${encodeURIComponent(error.message)}&code=${encodeURIComponent(error.code)}&stack=${encodeURIComponent(error.stack)}`;
                
                // Reuse the token for the logging request
                const token = await getToken(msalInstance, request,baseUri);
                const loggingConfig = {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                    }
                };
                try {
                    await axios.get(recordFailedPostUrl,loggingConfig);
                } catch (logError) {
                    console.error('Failed to log the failed POST request:', logError);
                }

                // Attempt the fetch API as a fallback
                const fetchConfig = {
                    method: error.config.method,
                    headers: {
                        'Authorization': error.config.headers.Authorization,
                        'Accept': 'application/json',
                        'Content-Type': error.config.headers['Content-Type'],
                    },
                    body: JSON.stringify(error.config.data),
                    cache: "no-cache"
                };

                try {
                    const response = await fetch(error.config.url, fetchConfig);
                    if (response.ok) {
                        const data = await response.json();
                        return Promise.resolve({ data, status: response.status });
                    } else {
                        return Promise.reject(new Error(`Fetch error: ${response.statusText}`));
                    }
                } catch (fetchError) {
                    return Promise.reject(fetchError);
                }
            }

            return Promise.reject(error);
        }
    );

    return axiosInstance;
};



const isTokenExpired = (token) => {
    if (!token) return true;

    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const expiryTime = decodedToken.exp * 1000; // Convert to milliseconds
    const currentTime = Date.now();

    return currentTime > expiryTime;
};

const getToken = async (msalInstance, request,baseUrl) => {

//     const loginType = localStorage.getItem('loginType'); // Retrieve login type
//     if (loginType === 'Microsoft') {
//     try {
//         let accountObj = msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];

//         if (!accountObj) {
//             if (isInteractionInProgress) {
//                 await interactionPromise;
//             } else {
//                 isInteractionInProgress = true;
//                 interactionPromise = msalInstance.loginRedirect(request);
//                 await interactionPromise;
//                 isInteractionInProgress = false;
//             }
//         } else {
//             try {
//                 const tokenResponse = await msalInstance.acquireTokenSilent({ ...request, account: accountObj });
                
//                 // Check if the token is expired
//                 if (isTokenExpired(tokenResponse.accessToken)) {
//                     throw new InteractionRequiredAuthError("Token expired");
//                 }

//                 return tokenResponse.accessToken;
//             } catch (error) {
//                 if (error instanceof InteractionRequiredAuthError) {
//                     if (isInteractionInProgress) {
//                         await interactionPromise;
//                     } else {
//                         isInteractionInProgress = true;
//                         interactionPromise = msalInstance.acquireTokenRedirect(request);
//                         await interactionPromise;
//                         isInteractionInProgress = false;
//                     }
//                 } else {
//                     console.error(error);
//                     const appInsights = getAppInsights();
//                     if (appInsights) {
//                         appInsights.trackException({ exception: error });
//                     }
//                     throw error;
//                 }
//             }
//         }
//     } catch (error) {
//         console.error("Token retrieval failed", error);
//         const appInsights = getAppInsights();
//         if (appInsights) {
//             appInsights.trackException({ exception: error });
//         }
//         throw error;
//     }
// } else if (loginType === 'Custom') {
        // Custom token retrieval and refresh logic
        const accessTokenExpiration = new Date(localStorage.getItem('AccessTokenExpiration'));
        if (Date.now() > accessTokenExpiration - 60 * 1000) {

            const instance = axios.create({
                baseURL: baseUrl,
                withCredentials: true,
              });
              try   {
            const refreshResponse = await instance.get(`/User/Security/RefreshToken`);

            const { accessToken, accessTokenExpiration, refreshTokenExpiration } = refreshResponse.data;
            localStorage.setItem('AccessToken', accessToken);
            localStorage.setItem('AccessTokenExpiration', accessTokenExpiration);
            localStorage.setItem('RefreshTokenExpiration', refreshTokenExpiration);
            return accessToken;
        } catch (axiosErr) {
            window.location.href = '/';
        } 
          

          
        } else {
            return localStorage.getItem('AccessToken');
        }
    // }
};

export { getToken, isTokenExpired, isInteractionInProgress };


export default createAxiosInstance;
