import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { BrowserRouter as Router } from 'react-router-dom'

// MSAL imports
import { msalInstance } from "./Ajax";

import * as am5 from "@amcharts/amcharts5";


am5.addLicense("AM5C319380202");

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)

root.render(
  // <React.StrictMode>
  <Router>
    <Provider store={store}>
      <App pca={msalInstance} />
    </Provider>
  </Router>
  // </React.StrictMode>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
