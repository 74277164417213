import { objExtend } from '@museco/objextend';
import { PublicClientApplication, CacheLookupPolicy, EventType } from "@azure/msal-browser";
import { getUserPhotoMsGraph } from '@museco/msalfunctions';
import 'regenerator-runtime/runtime';
import { Notifications } from '@museco/notifications';
import { getAppInsights } from './TelemetryService';
import { getToken } from './tokenUtility.js';
import createAxiosInstance from './axiosInstance';
import { msalConfig } from 'src/authConfig';

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

const commonNotificationOpts = {
    theme: 'relax',
    layout: 'topRight',
    timeout: 2500,
    loseWith: ['click'],
    maxVisible: 5
};

let accountId = "";

let getEndpointInUse = function () {
    if (window.location.href.indexOf("staging.") != -1) {
        return process.env.REACT_APP_STAGING_API_URL;
    } 
    else {
        return process.env.REACT_APP_API_URL;
    }
}



export const msalInstance = new PublicClientApplication(msalConfig);

async function initializeMsal() {
    await msalInstance.initialize();

    msalInstance.handleRedirectPromise().then(response => {
        if (response) {
            const microsoftToken = response.accessToken;

             doAjax({
                method: 'POST',
                url: '/User/Security/ExchangeToken',
                data: microsoftToken,
                 authenticate:false,
              }).then((rtn) => {
  
            const { accessToken, accessTokenExpiration, refreshTokenExpiration } = rtn.data;
            localStorage.setItem('AccessToken', accessToken);
            localStorage.setItem('AccessTokenExpiration', accessTokenExpiration);
            localStorage.setItem('RefreshTokenExpiration', refreshTokenExpiration);
  
           

            const account = response.account;
            msalInstance.setActiveAccount(account);
            accountId = account.homeAccountId;
            const appInsights = getAppInsights();
            if (appInsights) {
                appInsights.setAuthenticatedUserContext(account.username, account.homeAccountId);
            }

            window.location.href = '/CheckAccount';});
        }
    }).catch(error => {
        console.error("Redirect error: ", error);
        const appInsights = getAppInsights();
        if (appInsights) {
            appInsights.trackException({ exception: error });
        }
    });
}


// Initialize MSAL instance
initializeMsal().catch(error => {
    console.error("Initialization error: ", error);
    const appInsights = getAppInsights();
    if (appInsights) {
        appInsights.trackException({ exception: error });
    }
});

export const request = {
    scopes: [`${process.env.REACT_APP_API_CLIENT_ID}/user_impersonation`],
    //extraQueryParameters: { domain_hint: config.defaultDomain },
    cacheLookupPolicy: CacheLookupPolicy.Default,
    extraScopesToConsent: [`User.Read`, `email`, `offline_access`, `openid`, `profile`],
};

export const graphRequest = {
    scopes: [`User.Read`, `email`, `offline_access`, `openid`, `profile`],
    //extraQueryParameters: { domain_hint: config.defaultDomain },
    cacheLookupPolicy: CacheLookupPolicy.Default,
    extraScopesToConsent: [`${process.env.REACT_APP_CLIENT_ID}/user_impersonation`],
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

const getAccountInfo = () => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    return activeAccount || accounts[0];
};

const errorNotification = (optsToUse, err, reject) => {
    if (optsToUse.errorNotification) {
        const message = typeof optsToUse.errorNotification === 'string' ? optsToUse.errorNotification : `Fetch Error: ${err}`;
        Notifications.error(message, optsToUse);
    }
    console.error(err);
    
    const appInsights = getAppInsights();
    if (appInsights) {
        appInsights.trackException({ exception: err });
    } else {
        console.warn("AppInsights is not initialized, cannot track exception.");
    }

    reject(err);
};


const apiDoAjax = (msalInstance, baseApiUrl, opts, request) => new Promise(async (resolve, reject) => {
    const defaultOpts = {
        method: 'GET',
        url: '',
        beforeNotification: false,
        progressMessage: false,
        successNotification: false,
        httpErrorNotification: true,
        errorNotification: true,
        data: {},
        cache: 'no-store',
        isFileData: false,
        authenticate: true,
    };

    const optsToUse = objExtend(true, defaultOpts, opts);

    if (optsToUse.beforeNotification) {
        Notifications.info(optsToUse.beforeNotification, optsToUse);
    }

    let progressNotification = null;
    let progressInterval = null;
    if (optsToUse.progressMessage) {
        progressNotification = Notifications.progress(optsToUse.progressMessage, optsToUse);
        progressInterval = setInterval(() => {
            let textToShow = optsToUse.progressMessage + '.'.repeat((Date.now() / 200 % 15) + 1);
            progressNotification.setText(textToShow);
        }, 200);
    }

    try {
        const axiosInstance = createAxiosInstance(msalInstance, request,baseApiUrl,optsToUse.authenticate);
        const axiosConfig = {
            method: optsToUse.method,
            url: baseApiUrl + optsToUse.url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': optsToUse.isFileData ? undefined : 'application/json',
            },
            data: optsToUse.method === 'POST' ? optsToUse.data : undefined,
            responseType:  'json',
        };

        const axiosResponse = await axiosInstance(axiosConfig);
        await handleResponse(axiosResponse, optsToUse, baseApiUrl, reject, resolve);
        resolve(true);
    } catch (axiosErr) {
        handleAxiosError(axiosErr, optsToUse, baseApiUrl, reject, resolve);
    } finally {
        if (optsToUse.progressMessage) {
            clearInterval(progressInterval);
            progressNotification.close();
        }
    }
});

const handleAxiosError = async (error, optsToUse, baseApiUrl, reject, resolve) => {
    console.error('Axios error: ', error);

    if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        const appInsights = getAppInsights();
        if (appInsights) {
            appInsights.trackEvent({
                name: 'Axios Error Response',
                properties: {
                    status: error.response.status,
                    data: error.response.data,
                    headers: error.response.headers,
                    url: baseApiUrl + optsToUse.url,
                    user: getAccountInfo()
                }
            });
        }
        reject(`Error: ${error.response.status}`);
    } else if (error.request) {
        // The request was made but no response was received
        const appInsights = getAppInsights();
        if (appInsights) {
            appInsights.trackEvent({
                name: 'Axios No Response',
                properties: {
                    request: error.request,
                    url: baseApiUrl + optsToUse.url,
                    user: getAccountInfo()
                }
            });
        }
        // Attempt the fetch API as a fallback
        await fetchApi(baseApiUrl, optsToUse, reject, resolve);
    } else {
        // Something happened in setting up the request that triggered an Error
        const appInsights = getAppInsights();
        if (appInsights) {
            appInsights.trackEvent({
                name: 'Axios Request Error',
                properties: {
                    message: error.message,
                    url: baseApiUrl + optsToUse.url,
                    user: getAccountInfo()
                }
            });
        }
        reject(`Error: ${error.message}`);
    }
};

const fetchApi = async (baseApiUrl, optsToUse, reject, resolve) => {
    const obj = optsToUse.isFileData ? getTokenHttpForFileDataObj(optsToUse,baseApiUrl) : getTokenHttpObj(optsToUse,baseApiUrl);
    try {
        const response = await fetch(baseApiUrl + optsToUse.url, obj);
        await handleResponse(response, optsToUse, baseApiUrl, reject, resolve);
        resolve(true);
    } catch (err) {
        handleError(err, optsToUse, baseApiUrl, reject);
    } finally {
        if (optsToUse.progressMessage) {
            // clearInterval(progressInterval);
            // progressNotification.close();
        }
    }
};

const handleResponse = async (response, optsToUse, baseApiUrl, reject, resolve) => {
    const status = response.status || response.statusCode; // Axios response status compatibility
    if (status !== 200) {
        if (status === 400) {
            const data = response.data || await response.json();
            const errors = data.errors || {};
            for (const key of Object.keys(errors)) {
                for (const error of errors[key]) {
                    Notifications.error(`${key} - ${error}`, optsToUse);
                }
            }
            const appInsights = getAppInsights();
            if (appInsights) {
                appInsights.trackEvent({ name: '400 Error', properties: { status: status, url: baseApiUrl + optsToUse.url, user: getAccountInfo() } });
            }
            reject('Invalid response code');
        } else if (status === 401) {
            const appInsights = getAppInsights();
            if (appInsights) {
                appInsights.trackEvent({ name: '401 Unauthorized', properties: { status: status, url: baseApiUrl + optsToUse.url, user: getAccountInfo() } });
            }
            reject('Unauthorized');
        } else {
            Notifications.error(`Invalid response code - ${status}`, optsToUse);
            const appInsights = getAppInsights();
            if (appInsights) {
                appInsights.trackEvent({ name: 'Error', properties: { status: status, url: baseApiUrl + optsToUse.url, user: getAccountInfo() } });
            }
            reject(`Invalid response code - ${status}`);
        }
    } else {
        const data = response.data || await response.json();
        if (data.success) {
            if (optsToUse.successNotification) {
                Notifications.success(optsToUse.successNotification, optsToUse);
            }
            resolve(data);
        } else {
            if (optsToUse.errorNotification) {
            Notifications.error(data.msg, optsToUse);
            }
            const appInsights = getAppInsights();
            if (appInsights) {
                appInsights.trackEvent({ name: 'Error Response', properties: { message: data.msg, url: baseApiUrl + optsToUse.url, user: getAccountInfo() } });
            }
            reject(data.msg);
        }
    }
};

const handleError = (err, optsToUse, baseApiUrl, reject) => {
    if (err.message && err.message.includes("failed to fetch")) {
        console.error("Network error or handshake failure:", err);
        const appInsights = getAppInsights();
        if (appInsights) {
            appInsights.trackEvent({ name: 'Network/Handshake Failure', properties: { message: err.message, url: baseApiUrl + optsToUse.url, user: getAccountInfo() } });
        }
    } else if (err instanceof TypeError && err.message === 'Failed to fetch') {
        console.error("Possibly due to a network issue or a CORS policy problem:", err);
        const appInsights = getAppInsights();
        if (appInsights) {
            appInsights.trackEvent({ name: 'Fetch Failure', properties: { message: err.message, url: baseApiUrl + optsToUse.url, user: getAccountInfo() } });
        }
    } else {
        console.error("Unexpected error:", err);
        const appInsights = getAppInsights();
        if (appInsights) {
            appInsights.trackEvent({ name: 'Unexpected Error', properties: { message: err.message, url: baseApiUrl + optsToUse.url, user: getAccountInfo() } });
        }
    }
    errorNotification(optsToUse, err, reject);
};

const getTokenHttpObj = (optsToUse,baseApiUrl) => ({
    method: optsToUse.method,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken(msalInstance, request,baseApiUrl)}`
    },
    body: optsToUse.method === 'POST' ? JSON.stringify(optsToUse.data) : null
});

const getTokenHttpForFileDataObj = (optsToUse,baseApiUrl) => ({
    method: optsToUse.method,
    headers: {
        'Authorization': `Bearer ${getToken(msalInstance, request,baseApiUrl)}`
    },
    body: optsToUse.data
});

export async function callMsGraph(msalInstance, loginRequest, graphConfig) {
    const account = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API


    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}



const apiGetFile = async (msalInstance, baseApiUrl, opts, apiClientId) => {
    const defaultOpts = {
        fileName: opts.fileName,
        mimeType: opts.mimeType,
        progressMessage: false,
    };

    const optsToUse = objExtend(true, defaultOpts, opts);

    let progressNotification = null;
    let progressInterval = null;
    let progressNumber = 0;

    if (optsToUse.progressMessage !== false && typeof optsToUse.progressMessage === 'string') {
        progressNotification = Notifications.progress(optsToUse.progressMessage, optsToUse);
        progressInterval = setInterval(() => {
            progressNumber = (progressNumber + 1) % 16;
            let textToShow = optsToUse.progressMessage + '.'.repeat(progressNumber);
            progressNotification.setText(textToShow);
        }, 200);
    }

    try {
        const token = await getToken(msalInstance, apiClientId,baseApiUrl);
        const fetchConfig = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',	
                'Authorization': `Bearer ${token}`,
            },
            cache: "no-cache"
        };

        const response = await fetch(baseApiUrl + optsToUse.url, fetchConfig);

        if (!response.ok) {
            throw new Error('Error getting details for file');
        }

        const contentType = response.headers.get('Content-Type');
        let blob;

        if (contentType && contentType.indexOf('application/json') > -1) {
            const jsonResponse = await response.json();
            const binaryString = atob(jsonResponse);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            blob = new Blob([bytes], { type: optsToUse.mimeType });
        } else {
            blob = await response.blob();
        }

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', optsToUse.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        if (optsToUse.progressMessage !== false && typeof optsToUse.progressMessage === 'string') {
            clearInterval(progressInterval);
            progressNotification.close();
        }

        return true;
    } catch (err) {
        handleError(err, optsToUse, baseApiUrl);
        if (optsToUse.progressMessage !== false && typeof optsToUse.progressMessage === 'string') {
            clearInterval(progressInterval);
            progressNotification.close();
        }
        throw err;
    }
};







export async function refreshAccessToken() {
    return await getToken(msalInstance, request,getEndpointInUse());
}

msalInstance.addEventCallback((event) => {
    const appInsights = getAppInsights();
    switch (event.eventType) {
        case EventType.LOGIN_SUCCESS:
            if (event.payload.account) {
                const account = event.payload.account;
                msalInstance.setActiveAccount(account);
                accountId = account.homeAccountId;
                if (appInsights) {
                    appInsights.setAuthenticatedUserContext(account.username, account.homeAccountId);
                }
            }
            console.info("Login successful: ", event);
            break;

        case EventType.LOGIN_FAILURE:
            console.error("Login failed: ", event.error);
            if (appInsights) {
                appInsights.trackException({ exception: event.error });
            }
            break;

        case EventType.ACQUIRE_TOKEN_SUCCESS:
            console.info("Token acquired: ", event);
            if (appInsights) {
                appInsights.trackEvent({ name: 'Token Acquired', properties: { account: event.payload.account } });
            }
            break;

        case EventType.ACQUIRE_TOKEN_FAILURE:
            console.error("Token acquisition failed: ", event.error);
            if (appInsights) {
                appInsights.trackException({ exception: event.error });
            }
            break;

        case EventType.LOGOUT_SUCCESS:
            console.info("Logout successful: ", event);
            if (appInsights) {
                appInsights.trackEvent({ name: 'Logout Success' });
            }
            break;

        case EventType.LOGOUT_FAILURE:
            console.error("Logout failed: ", event.error);
            if (appInsights) {
                appInsights.trackException({ exception: event.error });
            }
            break;

        case EventType.SSO_SILENT_SUCCESS:
            console.info("SSO silent success: ", event);
            if (appInsights) {
                appInsights.trackEvent({ name: 'SSO Silent Success', properties: { account: event.payload.account } });
            }
            break;

       case EventType.SSO_SILENT_FAILURE:
            console.error("SSO silent failed: ", event.error);
            if (appInsights) {
                appInsights.trackException({ exception: event.error });
            }
            if (event.error?.errorCode === 'monitor_window_timeout') {
                msalInstance.acquireTokenRedirect(request);
            }
            break;
            
        case EventType.HANDLE_REDIRECT_START:
            console.info("Handle redirect start: ", event);
            if (appInsights) {
                appInsights.trackEvent({ name: 'Handle Redirect Start' });
            }
            break;

        case EventType.HANDLE_REDIRECT_END:
            console.info("Handle redirect end: ", event);
            if (appInsights) {
                appInsights.trackEvent({ name: 'Handle Redirect End' });
            }
            break;

        case EventType.HANDLE_REDIRECT_FAILURE:
            console.error("Handle redirect failed: ", event.error);
            if (appInsights) {
                appInsights.trackException({ exception: event.error });
            }
            break;

        default:
            console.info("Event: ", event);
            if (appInsights) {
                appInsights.trackEvent({ name: 'MSAL Event', properties: { eventType: event.eventType } });
            }
            break;
    }
});



    export const logOut = () => {
        const activeAccount = msalInstance.getActiveAccount() || msalInstance.getAccountByHomeId(accountId);
        msalInstance.logout({ account: activeAccount });
    };
    
    export const getUserPhoto = () => getUserPhotoMsGraph(msalInstance, graphRequest);
    export const getUserDetails = () => callMsGraph(msalInstance, graphRequest, graphConfig);

export const CommonMimeTypes = {
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'pdf': 'application/pdf'
};

// Adding back the doAjax function
export const doAjax = (opts) => {
    return apiDoAjax(msalInstance, getEndpointInUse(), objExtend(true, commonNotificationOpts, opts), request);
};

export const getFile = (opts) => {
    return apiGetFile(msalInstance, getEndpointInUse(), objExtend(true, commonNotificationOpts, opts), request);
};


