// authUtils.js


export const isAuthenticated = () => {
  // Check for a valid refresh token in session storage
  const accessToken = localStorage.getItem('AccessToken');
  const refreshTokenExpiration = localStorage.getItem('RefreshTokenExpiration');
  const currentTime = Date.now();

  if (accessToken && refreshTokenExpiration && currentTime < new Date(refreshTokenExpiration)) {
    return true; // Refresh token is still valid
  }


  return false; // No valid access or refresh token
};