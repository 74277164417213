import React, { Suspense, useState, useEffect } from 'react'

import { Route, Routes, useNavigate } from 'react-router-dom'
import './scss/style.scss'
import { MsalProvider } from '@azure/msal-react'
import { CustomNavigationClient } from './utils/NavigationClient'
import ProtectedRoute from './layout/ProtectedRoute'
//Do not remove --required to register the icons 




const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout.jsx'))


// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
 const SignUp = React.lazy(() => import('./views/pages/register/SignUpPage'))
 const ProfileSetp = React.lazy(() => import('./views/pages/register/ProfileSetup'))
const Privacy = React.lazy(() => import('./views/pages/privacy/Privacy'))
const CheckAccount = React.lazy(() => import('./layout/CheckAccount.jsx'))
const ForgotPassword = React.lazy(() => import('./layout/ForgotPassword.jsx'))
const ResetPasswordConfirm = React.lazy(() => import('./layout/ResetPasswordConfirm.jsx'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// Email App
const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))
const SupportPage = React.lazy(() => import('./pages/support/SupportPage'))



// eslint-disable-next-line react/prop-types
function App({ pca }) {

  useEffect(() => {
    pca.handleRedirectPromise()
      .then((s) => {
  
        if (s !== null && s.account != null)
          //        currentAccount.setCurrentAccount(s.account.username, s.account.homeAccountId, s.account.tenantId);
          console.log('success');
      })
      .catch((a) => {
        console.log('err');
        console.log(a);
      });
  }, []);

  


  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        {/* <HashRouter> */}
        <Suspense fallback={loading}>
          <Pages />
        </Suspense>
        {/* </HashRouter> */}
      </MsalProvider>
    </ClientSideNavigation>
  )



}
/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */
// eslint-disable-next-line react/prop-types
function ClientSideNavigation({ pca, children }) {
  const navigate = useNavigate()
  const navigationClient = new CustomNavigationClient(navigate)
  // eslint-disable-next-line react/prop-types
  pca.setNavigationClient(navigationClient)

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true)
  useEffect(() => {
    setFirstRender(false)
  }, [])

  if (firstRender) {
    return null
  }

  return children
}



function Pages() {
  return (
    <Routes>   
      <Route exact path="/" name="Login Page" element={<Login />} />
   
       <Route exact path="/signup" name="Sign Up Page" element={<SignUp />} />
      <Route exact path="/profile-setup" name="Profile Setup Page" element={<ProfileSetp />} /> 
      <Route exact path="/privacy" name="Privacy Policy Page" element={<Privacy />} />
 
      <Route exact path="/support" name="Support Page" element={<SupportPage />} />     
      <Route exact path="/CheckAccount" name="Check Account" element={<CheckAccount />} />
      <Route exact path="/ForgotPassword" name="Reset Password" element={<ForgotPassword />} />
      <Route exact path="/ResetPasswordConfirm" name="Reset Password Confirm" element={<ResetPasswordConfirm />} />
      <Route exact path="/404" name="Page 404" element={<Page404 />} />
      <Route exact path="/500" name="Page 500" element={<Page500 />} />
      <Route path="p/apps/email/*" name="Email App" element={<EmailApp />} />
    
      <Route path="/p/*" name="Home" element={  <ProtectedRoute><DefaultLayout />  </ProtectedRoute>} />
    
    </Routes>
  )
}



export default App
